import Cross from 'assets/Cross.svg'
import HeaderLogo from 'assets/Header_Logo.svg'
import Menu from 'assets/Menu.svg'
import React, { useState } from 'react'
import { NavLink } from "react-router-dom"
import Banner from './Common/Banner'
import IconSet from './Common/IconSet'
import ToastMessages from './Common/ToastMessages'
import './Header.css'

const Header: React.FC = () => {
  const [show, setShow] = useState<boolean>(false);
  const [showBanner, setShowBanner] = useState<boolean>(true);
  const [bannerHeight, setBannerHeight] = useState<number>(0);

  const hideBannerHandler = () => {
    setShowBanner(false);
  }

  const bannerHeightHandler = (height: number) => {
    setBannerHeight(height);
  }

  return (
    <div style={{ width: '100%' }}>
      <div className="header-wrapper">
        <Banner showBanner={showBanner} hideBannerHandler={hideBannerHandler} bannerHeightHandler={bannerHeightHandler} />
        <div className="header-background-wrapper">
          <div className='header-inner-wrapper'>
            <div className='header-title'>
              <NavLink to="/" className='header-logo-container' onClick={(show) => show && setShow(!show)}>
                <img src={HeaderLogo} alt="header-logo" className='header-icon' />
              </NavLink>
            </div>
            <nav className='header-sections'>
              <div className='header-routes'>
                <NavLink to="/migrate" className={(navData) => navData.isActive ? "link-active" : "link hover-underline-blue"} >Migrate</NavLink>
                <NavLink to="/buy" className={(navData) => navData.isActive ? "link-active" : "link hover-underline-blue"} >Buy</NavLink>
                <NavLink to="/stake" className={(navData) => navData.isActive ? "link-active" : "link hover-underline-blue"}>Stake</NavLink>
                <a className='link hover-underline-blue' href={'https://carbon.network/'} target="_blank" rel="noopener noreferrer">
                  About Carbon
                </a>
              </div>
              <div className='header-icon-set'>
                <IconSet color='blue' />
              </div>
            </nav>
            <div className={`header-drop-down-button`} onClick={() => setShow(!show)}>
              <img src={show ? Cross : Menu} alt="Menu" />
            </div>
          </div>
        </div>
        <ToastMessages />
      </div>
      <nav className={`header-drop-down-menu ${show ? 'collapse' : 'uncollapse'}`} style={{ top: bannerHeight }}>
        <NavLink to="/migrate" className="header-link" onClick={() => setShow(!show)}>Migrate</NavLink>
        <NavLink to="/buy" className="header-link" onClick={() => setShow(!show)}>Buy</NavLink>
        <NavLink to="/stake" className="header-link" onClick={() => setShow(!show)}>Stake</NavLink>
        <a className='header-link' href={'https://carbon.network/'} target="_blank" rel="noopener noreferrer">
          About Carbon&nbsp;
        </a>
        <div className='icon-set-mobile'> <IconSet color='white' /></div>
      </nav>
    </div>

  );
};

export default Header;
