import './polyfill'

import Listener from 'components/Common/Listener'
import { DelegateCard } from 'components/StakePage/StakeCards'
import RedelegateCard from 'components/StakePage/StakeCards/components/RedelegateCard'
import UndelegateCard from 'components/StakePage/StakeCards/components/UndelegateCard'
import StakeFAQ from 'components/StakePage/StakeFAQ/StakeFAQ'
import StakeRewardsCard from 'components/StakePage/StakeRewards/components/StakeRewardsCard'
import "milligram"
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom"
import BuyPage from './components/BuyPage/BuyPage'
import GoogleAnalytics from './components/Common/GoogleAnalytics'
import Footer from './components/Footer'
import Header from './components/Header'
import HomePage from './components/HomePage/HomePage'
import MigratePage from './components/MigratePage/MigratePage'
import TokenMigrateCard from './components/MigratePage/TokenMigrateCard'
import StakePage from './components/StakePage/StakePage'
import './index.css'
import reportWebVitals from './reportWebVitals'
import ScrollToTop from "./ScrollToTop"
import { store } from './store/index'

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <GoogleAnalytics />
      <Listener />
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/migrate" element={<MigratePage />} />
        <Route path="/migrate/:slug" element={<TokenMigrateCard />} />
        <Route path="/buy" element={<BuyPage />} />
        <Route path="/stake" element={<StakePage />} />
        <Route path="/stake/faq" element={<StakeFAQ />} />
        <Route path="/stake/delegate" element={<DelegateCard />} />
        <Route path="/stake/redelegate" element={<RedelegateCard />} />
        <Route path="/stake/undelegate" element={<UndelegateCard />} />
        <Route path="/stake/rewards" element={<StakeRewardsCard />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </ Routes>
      <Footer />
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
